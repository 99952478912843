@font-face {
  font-family: 'Icon';
  src: url('../../static/fonts/icon/icon.eot');
  src: url('../../static/fonts/icon/icon.eot') format('embedded-opentype'),
    url('../../static/fonts/icon/icon.woff2') format('woff2'),
    url('../../static/fonts/icon/icon.woff') format('woff'),
    url('../../static/fonts/icon/icon.ttf') format('truetype'),
    url('../../static/fonts/icon/icon.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}