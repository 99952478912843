.hero {
	width: 100%;
	height: 100%;
	min-height: 100vh;
	overflow: hidden;
	position: relative;
	background-color: #ffffff;
}

.globe {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	height: auto;
	width: auto;
}

.globe__canvas {
	width: 100%;
	height: 100%;
}

.icons {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	pointer-events: none;
}

.icons__icon {
	color: #041834;
	position: absolute;
	top: calc(50% - 8px);
	left: calc(50% - 8px);
};